<template>
	<section class="content">
		<div class="modal show" tabindex="-1" role="dialog" ref="formPin" data-backdrop="static">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<button type="button" class="back" data-dismiss="modal" aria-label="Back" @click="backPin">
							<span aria-hidden="true">&lt;</span>
						</button>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="backPin">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body text-center" v-if="!issetPin">
						<div class="form-group">
							<img src="@/assets/img/pin.svg" class="mb-3" />
							<h5>Atur PIN otorisasi kamu agar dapat<br>melanjutkan proses tambah<br>rekening</h5>
						</div>
						<button @click="showSetPin()" class="btn btn-block btn-lg btn-primary">Atur PIN otorisasi</button>
					</div>
					<div class="modal-body text-center" v-if="issetPin">
						<div class="alert alert-danger alert-sm" v-if="!pinCheck">{{pinMessage}}</div>
						<div class="h5 font-weight-bold mb-5">Masukkan PIN otorisasi Kamu</div>
						<div class="row justify-content-center">
							<div class="col-auto"><div :class="(pinSet.substr(0,1) && pinStar[0] ? 'h4 py-2' : ((pinSet.substr(0,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(0,1) == '' || !pinStar[0] ? '&bull;' : pinSet.substr(0,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(1,1) && pinStar[1] ? 'h4 py-2' : ((pinSet.substr(1,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(1,1) == '' || !pinStar[1] ? '&bull;' : pinSet.substr(1,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(2,1) && pinStar[2] ? 'h4 py-2' : ((pinSet.substr(2,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(2,1) == '' || !pinStar[2] ? '&bull;' : pinSet.substr(2,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(3,1) && pinStar[3] ? 'h4 py-2' : ((pinSet.substr(3,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(3,1) == '' || !pinStar[3] ? '&bull;' : pinSet.substr(3,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(4,1) && pinStar[4] ? 'h4 py-2' : ((pinSet.substr(4,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(4,1) == '' || !pinStar[4] ? '&bull;' : pinSet.substr(4,1)}}</div></div>
							<div class="col-auto"><div :class="(pinSet.substr(5,1) && pinStar[5] ? 'h4 py-2' : ((pinSet.substr(5,1) ? 'text-primary' : 'text-light') + ' h1 py-0 font-weight-bold')) + ' border-bottom border-primary px-2'">{{pinSet.substr(5,1) == '' || !pinStar[5] ? '&bull;' : pinSet.substr(5,1)}}</div></div>
						</div>
						<div class="overlay d-flex justify-content-center align-items-center" v-if="loadingPin"><h1 style="font-size:40px;">Sedang diproses <i class="fas fa-sync-alt fa-spin"></i></h1></div>
						<div class="h6 font-weight mt-3">Masukkan 6 digit PIN otorisasi kamu untuk melanjutkan proses {{messagePin}}</div>
						<div class="m-auto" style="max-width: 400px">
							<div class="row justify-content-center">
								<div class="col-3 btn btn-primary m-1" v-for="index in 9" :key="index" @click="pinKeyPress(index)">
									<span class="h2">{{ index }}</span>
								</div>
								<div class="col-3 btn btn-primary m-1 d-flex justify-content-center align-items-center" @click="pinKeyPress('Clear')">
									<span class="h2 mb-0"><i class="fi fi-rr-arrows-repeat d-flex justify-content-center align-items-center"></i></span>
								</div>
								<div class="col-3 btn btn-primary m-1" @click="pinKeyPress('0')">
									<span class="h2">0</span>
								</div>
								<div class="col-3 btn btn-primary m-1 d-flex justify-content-center align-items-center" @click="pinKeyPress('Backspace')">
									<span class="h2 mb-0"><i class="fi fi-rr-delete d-flex justify-content-center align-items-center"></i></span>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer" hidden></div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import $ from "jquery";
import { authFetch } from "@/libs/hxcore";

export default {
	props: {
		showPin: Boolean,
		loadingPin: Boolean,
		messagePin: String,
	},
	data() {
		return {
			issetPin: false,
			pinSet: '',
			pinStar: [false, false, false, false, false, false],
			pinCheck: true,
			pinMessage: '',
		};
	},
	components: {},
	created() {},
	beforeDestroy() {
		// window.removeEventListener('keyup', this.pinKeyPress);
	},
	methods: {
		backPin() {
			this.pinSet = '';
			// window.removeEventListener('keyup', this.pinKeyPress);
			$(this.$refs.formPin).modal("hide");
			if (!this.issetPin) {
				this.$router.back();
			}
		},
		pinKeyPress(e) {
			var char = parseInt(e);
			if (Number.isInteger(char) && this.pinSet.length < 6) {
				this.pinStar[this.pinSet.length - 1] = false;
				this.pinStar[this.pinSet.length - 0] = true;
				this.pinSet = this.pinSet + char;
				if (this.pinSet.length > 5) {
					this.checkPin();
				}
			} else if (e == 'Backspace') {
				this.pinSet = this.pinSet.substr(0, this.pinSet.length - 1);
				this.pinStar[this.pinSet.length - 0] = false;
				this.pinStar[this.pinSet.length - 1] = true;
			} else if (e == 'Clear') {
				this.pinSet = '';
			}
		},
		showSetPin() {
			$(this.$refs.formPin).modal("hide");
			this.$router.push({ path: "/settings/pin"});
		},
		checkPin: function () {
			this.pinStar[this.pinSet.length - 1] = false;
			this.$emit("change", this.pinSet);
			this.issetPin = false;
			this.pinSet = '';
			this.pinCheck = true;
			this.pinMessage = '';
			$(this.$refs.formPin).modal("hide");
		},
	},
	mounted() {},
	watch: {
		showPin: function (old, val) {
			$(this.$refs.formPin).modal("hide");
			authFetch("/shipper/isset_pin")
			.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
			})
			.then((js) => {
				if (js.success) {
					this.issetPin = true;
					// window.addEventListener('keyup', this.pinKeyPress);
				}
				$(this.$refs.formPin).modal("show");
			});
		},
		messagePin: function (old, val) {},
	}
};
</script>
<style scoped>
	div.vue-pincode-input-wrapper {
		display: flex;
		justify-content: center;
	}

	input.vue-pincode-input {
		border-bottom: 1px solid orange;
		box-shadow: none !important;
	}
</style>