const sideMenu2 = [
    { label: 'Dashboard', url: '/dashboard-admin', icon: 'fas fa-tachometer-alt' },
    { label: 'Cek Status', url: '/cek', icon: 'fa fa-list-ul' },
    { label: 'Riwayat Kiriman', url: '/riwayat/admin/all', icon: 'far fa-clock' },
    { label: 'Payment', url: '/payment-admin/all', icon: 'fas fa-money-bill' },
    //   { label: 'Payment', url: '/payment', icon: 'fa fa-money-bill' },
    { label: 'Profil', url: '/profile', icon: 'far fa-user' },
    {
        label: 'Pengumuman',
        url: '#',
        icon: 'fa fa-bell',
        menus: [
            { label: 'Blast', url: '/blast-notif', icon: 'far fa-circle' },
            { label: 'List Pengumuman', url: '/pengumuman', icon: 'far fa-circle' },
        ]
    },
    {
        label: 'Data Rekonsiliasi',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Rekonsiliasi', url: '/rekonsiliasi', icon: 'far fa-circle' },
            // { label: 'Upload <br> Rekonsiliasi Shipper', url: '/rekonsiliasi/shipper/upload', icon: 'far fa-circle' },
            { label: 'Upload <br> Rekonsiliasi Kurir', url: '/rekonsiliasi/kurir/upload', icon: 'far fa-circle' },
        ]
    },
    {
        label: 'Data Shipper',
        url: '#',
        icon: 'fa fa-users',
        menus: [
            { label: 'Shipper', url: '/shipper', icon: 'far fa-circle' },
            { label: 'Rekening Shipper', url: '/shipper/rekening', icon: 'far fa-credit-card' },
            { label: 'Shipper CS', url: '/settings/user', icon: 'fa fa-users' },
            { label: 'Gudang Shipper', url: '/settings/shipper/address', icon: 'far fa-circle' },
            { label: 'Kiriman', url: '/shipper/recap', icon: 'fa fa-circle' },
            { label: 'Top-Up', url: '/saldo/topup/approval', icon: 'far fa-circle' },
            { label: 'Withdraw', url: '/saldo/withdraw/approval', icon: 'far fa-circle' },
            { label: 'Rekap Saldo', url: '/saldo/shipper', icon: 'far fa-circle' },
            { label: 'Financial Report', url: '/finance/shipper', icon: 'far fa-circle' },
            { label: 'Afiliator', url: '/afiliator', icon: 'far fa-circle' },
            { label: 'Reward Afiliasi', url: '/afiliasi-shipper', icon: 'far fa-circle' },
            { label: 'Verifikasi KTP', url: '/shipper/verification', icon: 'far fa-circle' },
            { label: 'Generate Kurir Shipper', url: '/shipper/courier/generate', icon: 'far fa-circle' },
        ]
    },
    //{ label: 'Buyer', url: '/buyer', icon: 'fa fa-users' },

    {
        label: 'Ticket',
        url: '#',
        icon: 'fa fa-cog',
        menus: [{
                label: 'Buat baru',
                url: () => {
                    //this.$root.$emit('createTicket', 3);
                },
                icon: 'far fa-circle'
            },
            { label: 'List', url: '/settings/tickets/all', icon: 'far fa-circle' },
            { label: 'Claim', url: '/settings/tickets/claim', icon: 'far fa-circle' },
        ]
    },

    {
        label: 'Master',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Kurir', url: '/settings/courier', icon: 'hxf hxf-delivery' },

            { label: 'User Kurir', url: '/user/kurir', icon: 'fa fa-users' },
            { label: 'Service Kurir', url: '/settings/courier_service', icon: 'fa fa-tasks' },
            { label: 'Jenis Member', url: '/settings/membertype', icon: 'fa fa-star' },
            { label: 'Kode Tujuan', url: '/settings/destination', icon: 'far fa-circle' },
            { label: 'Pages', url: '/info', icon: 'far fa-circle' },
            { label: 'Rekening BOSCOD', url: '/settings/banklist', icon: 'far fa-circle' },
            // { label: 'Afiliasi', url: '/afiliasi', icon: 'far fa-circle' },
        ]
    },
    {
        label: 'Laporan',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Buku Besar', url: '/report/bukubesar', icon: 'fa fa-circle' },
            { label: 'Trial Balance', url: '/report/trialbalance', icon: 'fa fa-circle' },
            { label: 'Bospay', url: '/report/bospay', icon: 'fa fa-circle' },
            { label: 'Hutang Usaha (Kurir)', url: '/report/hutang/kurir', icon: 'fa fa-circle' },
            { label: 'Hutang COD (Shipper)', url: '/report/hutang/shipper', icon: 'fa fa-circle' },
            { label: 'Piutang', url: '/report/piutang-new', icon: 'fa fa-circle' },
            { label: 'PPN Masukan', url: '/report/ppn-masukan', icon: 'fa fa-circle' },
            { label: 'PPN Keluaran', url: '/report/ppn-keluaran', icon: 'fa fa-circle' },
            { label: 'Laba Rugi', url: '/report/labarugi', icon: 'fa fa-circle' },
            { label: 'Neraca', url: '/report/neraca', icon: 'fa fa-circle' },
            { label: 'Jurnal Entri', url: '/report/jurnal-entri', icon: 'fa fa-circle' },
            { label: 'Jurnal Group', url: '/report/jurnal-group', icon: 'fa fa-circle' },
            { label: 'Kewajaran', url: '/report/kewajaran', icon: 'fa fa-circle' },
            { label: 'Tutup Buku', url: '/report/close-jurnal', icon: 'fa fa-circle' },
        ]
    },
    {
        label: 'Winpay',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Laporan Payment Gateway', url: '/report/winpay', icon: 'fa fa-circle' },
            { label: 'Upload Rekonsiliasi Winpay', url: '/rekonsiliasi/winpay', icon: 'fa fa-circle' }
        ]
    },
    {
        label: 'PPOB',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Transaksi PPOB', url: '/ppob/history', icon: 'fa fa-circle' },
            { label: 'Produk PPOB', url: '/ppob/products', icon: 'fa fa-circle' },
            { label: 'Upload Rekon PPOB Winpay', url: '/ppob/rekonsiliasi/winpay', icon: 'fa fa-circle' },
            { label: 'Laporan Subsidiary Ledger akun Uang Muka', url: '/ppob/subsidiary-ledger', icon: 'fa fa-circle' },
        ]
    },
    {
        label: 'Update Tracking',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Cek Tracking <br> Kurir', url: '/tracking', icon: 'far fa-circle' },

        ]
    }

];
export default sideMenu2;
