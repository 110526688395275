<template>
  <aside class="main-sidebar sidebar-light-olive">
    <div v-if="true" class="loading-overlay d-flex justify-content-center align-items-center"><div class="h1 text-light align-text-center">Memuat halaman <i class="fas fa-sync-alt fa-spin"></i></div></div>
    <div class="box-title text-left">
      <router-link to="/" class="logo-height">
        <img :src="logo" alt="bosCOD Platform Kurir COD/Non-COD" class="wide logo-height mx-2"
          style="margin-top: 10px;height:50px;">
        <img src="@/assets/img/logo-img.png" alt="bosCOD Platform Kurir COD/Non-COD"
          class="lite logo-height img-circle"></router-link>
    </div>

    <div class="bg-success p-2 new-update" v-if="updateAvailable">
      <a href="#" v-on:click="hardReload"><span class="info-box-icon img-circle p-1 text-center text-success"
          style="line-height: 1.5;background-color:#fff; font-size:16px;width:32px;height:32px;display:inline-block;">
          <i class="fa fa-info"></i></span><span class="new-update-link"> Update Sekarang <i
            class="fa fa-angle-right"></i></span>
      </a>
    </div>
    <!-- Sidebar -->
    <div class="sidebar" ref="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column pb-5" ref="treeview" data-widget="treeview" role="menu"
          data-accordion="false" v-html="getSideMenu" @click="handleClick">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-header">Examples</li>
          <li class="nav-item">
            <router-link to="/table" class="nav-link">
              <i class="fas fa-circle nav-icon"></i>
              <p>Table</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/form" class="nav-link">
              <i class="fas fa-circle nav-icon"></i>
              <p>Form</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import Vue from 'vue'
import { auth, authFetch } from '@/libs/hxcore';
import sideMenuAdmin from '@/sidemenu';
import sideMenuBosAdmin from '@/sidemenu2';
import sideMenuBos3 from '@/sidemenu3';
import sideMenuBos4 from '@/sidemenu4';
import sideMenuBos5 from '@/sidemenu5';
import sideMenuBosClaim from '@/sidemenu10';
import sideMenushipper from '@/sidemenushipper';
import sideMenushipperCs from '@/sidemenushippercs';
import sideMenukurir from '@/sidemenukurir';
import sideMenuAssistAdmin from '@/sidemenu13';
import $ from 'jquery';
export default {
  name: "AppLayoutMenu",
  data() {
    return {
      user: null,
      logo: '',
      sideMenu: '',
      newUpdate: '',
      updateAvailable: false // update aplikasi
    };
  },
  computed: {
    getAvatar: function () {
      return this.user ? this.user.avatar : ''
    },
    getSideMenu: function () {
      var info = auth.user();
      if (info) {
        var output = '<li class="nav-header">MENU</li>';
        var output = '';
        var menu = null;
        if (info.role_id == 11) menu = sideMenushipper;
        else if (info.role_id == 12) menu = sideMenushipperCs;
        else if (info.role_id == 9) menu = sideMenukurir;
        else if (info.role_id == 1) menu = sideMenuAdmin;
        else if (info.role_id == 2) menu = sideMenuBosAdmin;
        else if (info.role_id == 3) menu = sideMenuBos3;
        else if (info.role_id == 4) menu = sideMenuBos4;
        else if (info.role_id == 5) menu = sideMenuBos5;
        else if (info.role_id == 10) menu = sideMenuBosClaim;
        else if (info.role_id == 6) menu = sideMenuAssistAdmin;
        this.loadTicket()
        return output + this.getWalker(menu);
      }

    }
  },
  methods: {
    hardReload: function () {
      location.reload(true);
      return false;
    },
    loadTicket: function (code) {
      authFetch("/ticket/history/newupdate")
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.success) {
            this.newUpdate = json.total > 0 ? json.total : '';
          }
          else {
            this.newUpdate = '';
          }
        });
    },
    handleClick(e) {
      var target = e.target.closest('.nav-link');
      if (target) {
        e.preventDefault();
        if (!target.getAttribute('href')) {
          if (target.dataset.click) {
            this.$root.$emit('createTicket', '');
          }
          return;
        }
        //else 
        //target.classList.add('active');
        if (target.getAttribute('href') == '#') {
          e.preventDefault();
          return;
        }
        if (this.$route.path != target.getAttribute('href')) {
          this.$router.push({ path: target.getAttribute('href') })
          if (!(this.$route.path == '/' && target.getAttribute('href').substr(0, 10) == '/dashboard')) {
            $('.loading-overlay').addClass('show');
          }
        }
      }
    },
    inMenu(menus, path) {
      if (!menus) return false;
      for (var i in menus) {
        if (menus[i].url == path) {
          return true;
        }
      }
      return false;
    },
    getWalker(menus) {
      var output = '';
      var currPath = this.$route.path;

      for (var k in menus) {
        var menu = menus[k];
        var menuOpen = '';
        var menuOpenActive = '';
        var clsLink = '';

        if (currPath == menu.url) clsLink = 'active';

        var link = '';
        var clickCallback = null;

        if (typeof menu.url == 'string') {
          link = 'to="' + menu.url + '" href="' + menu.url + '"';
        } else {
          clickCallback = menu.url;
        }

        if (this.inMenu(menu.menus, currPath)) {
          menuOpen = 'menu-open';
          clsLink = 'active';
        }

        var ticketBadge = '';

        if (menu.label == 'Ticket') {
          ticketBadge = '<span class="badge badge-primary right" style="padding:0.4em;">' + this.newUpdate + '</span>';
        }

        var tmp = '<li class="' + (menu.menus ? 'nav-item has-treeview' : 'nav-item') + ' ' + menuOpen + '">';
        tmp += '<a style="cursor:pointer;" ' + link + ' class="nav-link ' + clsLink + '" data-click="' + clickCallback + '">';
        tmp += '<i class="' + (menu.icon ? menu.icon : 'd-inline-block') + ' nav-icon"></i>';
        tmp += '<p>' + menu.label + (menu.menus ? '<i class="right fi fi-rr-angle-small-right"></i>' : '') + ticketBadge + '</p>';
        tmp += '</a>';

        if (menu.menus) {
          tmp += '<ul class="nav nav-treeview">';
          tmp += this.getWalker(menu.menus);
          tmp += '</ul>';
        }
        tmp += '</li>';
        output += tmp;
      }
      return output;
    }
  },
  created() {
    if (Vue.config.appHost == 'xpress')
      this.logo = require(`../assets/img/logo-xpress-text.png`);
    else this.logo = require(`../assets/img/logo-text.svg`);
    // Simulate fetching user data.
    setTimeout(() => {
      this.user = { name: `John Doe`, avatar: 'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y' };
    }, 2000);
  }
  , mounted() {
    this.$root.$on('reloadMenuTicket', () => {
      this.loadTicket();
    })
    if (!Vue.prototype.loadMenu) {
      $(this.$refs.treeview).Treeview('init');
      $(this.$refs.sidebar).overlayScrollbars({
        className: 'os-theme-dark',
        sizeAutoCapable: true,
        scrollbars: {
          autoHide: true,
          clickScrolling: true
        }
      });
      Vue.prototype.loadMenu = true;
    }

  },
  beforeDestroy() {
  }
}
</script>
<style>
.sidebar-collapse .new-update {
  display: none;
}
.main-sidebar{
  z-index: 1033;
}

#sidebar-overlay{
  z-index: 1032;
}
</style>
