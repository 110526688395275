<template>
  <div v-if="isAdmin">
      <Alert page="layout" position="top" />
    <div class="wrapper">
      <AppLayoutNav />
      <AppLayoutMenu />
      <div class="content-wrapper">

      <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>{{ title == 'Maintenance' ? '' : title }}</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active">{{ title }}</li>
              </ol>
            </div>
          </div>
        </div><!-- /.container-fluid -->
      </section>

      <slot />
      <Ticket :show="openTicket" :orderId="orderCode" v-on:hide="ticketHide" />
      <TicketComment
      :show="isShowTicket"
      :orderId="orderCodeComment"
    />      
      </div>
      <footer class="main-footer">
        <div class="float-right d-none d-sm-block">
          <b>Version</b> 1.1
        </div>
        <strong>Copyright &copy; {{date}} <a :href="'http://'+footerTitle+'.com'" target="_blank">{{footerTitle}}.com</a>.</strong> All rights reserved.
      </footer>
    </div>
  </div>
  <div v-else>
    <slot />
  </div>
</template>
<script>
//import firebase from "firebase/app";
//import "firebase/messaging";
import {
	auth
} from '@/libs/hxcore';
import Swal from "sweetalert2";
import AppLayoutNav from '@/layouts/AppLayoutNav'
import AppLayoutMenu from '@/layouts/AppLayoutMenu'
import Vue from 'vue'
import Ticket from "@/dialog/Ticket";
import TicketComment from "@/dialog/TicketComment";
import Alert from "@/dialog/Alert";
const defaultLayout = 'AppLayoutDefault'

var Tawk_API = Tawk_API || {};
export default {
	name: "AppLayout",
	data() {
		return {
			chatLoaded: false,
			title: null,
			orderCode: '',
			orderCodeComment: '',
			openTicket: false,
			isShowTicket: false,
			date: new Date(),
			userInfo: {},
			connection: null,
            footerTitle: '',
		};
	},
	watch: {
		$route(to, from) {
			this.title = to.name;
			this.userInfo = auth.user();
			if (Vue.config.enableChat && !this.chatLoaded) {
				this.chat()
				this.chatLoaded = true;
			} /*else if (to.path == '/login') {
				//hide
				if (Tawk_API.hideWidget) Tawk_API.hideWidget()
			} else {
				if (Tawk_API.showWidget) Tawk_API.showWidget()
			}
			if (to.meta.layout == 'AppLayoutAdmin') {
				//this.requestPermission();
			}*/
			if(to.path == '/login')
			{
				if(this.connection)this.connection.close();
				this.connection = null;
				window.wbsconn = null;
			}
			else 
			{
				if(!this.connection)this.connectSocket();
			}
		}
	},
	mounted() {
		this.date = this.date.getFullYear();
		this.title = this.$route.name;
		this.$root.$on('onRouteChanged', (data) => { // here you need to use the arrow function
		})
		this.$root.$on('createTicket', (e) => {
			this.orderCode = e;
			this.openTicket = !this.openTicket;
		})
		this.$root.$on('openTicketComment', (e) => {
			this.orderCodeComment = e;
			this.isShowTicket = !this.isShowTicket;
		})
	},
	created: function () {
		this.userInfo = auth.user();
		this.connectSocket();

        if(Vue.config.appHost == 'xpress')
			this.footerTitle = 'bosxpress';
		else this.footerTitle = 'boscod';
	},
	computed: {
		layout() {
			const layout = this.$route.meta.layout || defaultLayout
			return () => import(`@/layouts/${layout}.vue`)
		},
		isAdmin() {
			var nilai = (this.$route.meta.layout == 'AppLayoutAdmin') ? true : false;
			//console.log(nilai);
			return nilai;
		}
	},
	methods: {
		requestPermission() {
			try {
				firebase
					.messaging()
					.requestPermission()
					.then(() => {
						console.log("Notification permission granted");
						firebase
							.messaging()
							.getToken()
							.then((token) => {
								window.console.log("token ", token);
								if (!localStorage.fbKey) {
									localStorage.fbKey = 1;
									console.log('reg')
								}
								this.receiveMessage();
							});
					})
					.catch((err) => {
						console.log("Unable to get token ", err);
					});
			} catch (e) {
				console.log(e);
			}
		},
		receiveMessage() {
			try {
				firebase.messaging().onMessage((payload) => {
					console.log("payload ", payload);
				});
			} catch (e) {
				console.log(e);
			}
		},
		ticketHide: function () {},
		chat: function () {
			var info = auth.user();
			if (info && info.role_id < 10) {
				return;
			}
			var Tawk_LoadStart = new Date();
			var s1 = document.createElement("script"),
				s0 = document.getElementsByTagName("script")[0];
			s1.async = true;
			if(process.env.VUE_APP_TAWKTO_URL)
			{
				s1.src = process.env.VUE_APP_TAWKTO_URL;
			}
			else s1.src = 'https://embed.tawk.to/6100b983d6e7610a49ad4f9b/1fbld9bjq';
			//s1.src='https://embed.tawk.to/615abc5d25797d7a890224e9/1fh56vjtp';
			s1.charset = 'UTF-8';
			s1.setAttribute('crossorigin', '*');
			s0.parentNode.insertBefore(s1, s0);
			window.Tawk_API = Tawk_API
			window.Tawk_API.onLoad = function () {
				window.Tawk_API.hideWidget() // hide the chat
				if(info)Tawk_API.setAttributes({
					'name': info.display_name,
					'email': info.email,
					'hash': info.hash
				}, function (error) {
					console.log('err', error)
				});
				setTimeout(function () {
					window.Tawk_API.showWidget()
				}, 2000);
			};
			/*window.intercomSettings = {
			  app_id: "zbzzyg73",
			  name: info.username, // Full name
			  email: info.email, // Email address
			  //created_at: "<%= current_user.created_at.to_i %>"
			};*/
			// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/zbzzyg73'
			/*(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/zbzzyg73';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();*/
		},
		connectSocket(){
			if(!process.env.VUE_APP_WS_URL)return;
			var self = this;
			this.connection = new WebSocket(process.env.VUE_APP_WS_URL);
			this.connection.onmessage = function(event) {
				var data = JSON.parse(event.data);
				if(data && data.message)
				{
					Swal.fire({
						title: data.title?data.title:'',
						position: data.position?data.position:'top-end',
						icon: data.type ? data.type : '',
						text: data.message,
						timer:data.timeout,
						showConfirmButton: data.showButton,
						allowOutsideClick: !data.blocked,
					})
				}
				
			}

			this.connection.onopen = function(event) {
				window.wbsconn = self.connection;
				var info = auth.user();
				if(info){
                    var jwt = require('jsonwebtoken');
                    var encodeData = jwt.sign(info, 'bosData21');
					self.connection.send(JSON.stringify({type:'connect', data: encodeData}));
				}
			}
			this.connection.onerror = function (event) {
				self.connection.close();
				self.connection = null;
				window.wbsconn = null;
			};
			this.connection.onclose = function (event) {
				self.connection = null;
				window.wbsconn = null;
			};
		}
	},
	components: {
		AppLayoutNav,
		AppLayoutMenu,
		Ticket,
		TicketComment,
        Alert
	}
}
</script>
