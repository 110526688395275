(function(factory) {
        "use strict";

        /*if ( typeof define === 'function' && define.amd ) {
        	// AMD
        	console.log('sc define...');
        		
        	define( ['jquery'], function ( $ ) {
        		require('bstable');
        		return factory( $, window, document );
        	} );
        }
        else */
        if (typeof exports === 'object') {
            // CommonJS

            var root = null;
            var $ = null;
            if (!root) {
                // CommonJS environments without a window global must pass a
                // root. This will give an error otherwise
                root = window;
            }

            if (!$) {
                $ = typeof window !== 'undefined' ? // jQuery's factory checks for a global window
                    require('jquery') :
                    require('jquery')(root);
            }
            //require('bstable');

            require('datatables.net');
            require('datatables.net-bs4');
            require('datatables.net-rowgroup');
            require('datatables.net-rowgroup-bs4');
            var isMobile = function() {
                const toMatch = [
                    /Android/i,
                    /webOS/i,
                    /iPhone/i,
                    /iPad/i,
                    /iPod/i,
                    /BlackBerry/i,
                    /Windows Phone/i
                ];

                return toMatch.some((toMatchItem) => {
                    return navigator.userAgent.match(toMatchItem);
                });
            }
            module.exports = {
                createTable: factory($, root, root.document),
                authHeaders: function() {
                    var tokenData = localStorage['APP_TOKEN'];
                    var token = '';
                    if (tokenData) {
                        var arrToken = JSON.parse(tokenData);
                        token = 'Bearer ' + arrToken['accessToken'];
                    }

                    var headers = {
                        'Authorization': token
                    };
                    return headers;
                },
                authFetch: async(url, options) => {
                    var tokenData = localStorage['APP_TOKEN'];
                    var token = '';
                    if (tokenData) {
                        try {
                            JSON.parse(tokenData);
                        } catch (e) {
                            localStorage['APP_TOKEN'] = '';
                            location.href = '/login';
                            return false;
                        }
                        var arrToken = JSON.parse(tokenData);

                        if (!arrToken['refreshToken'] && window.$crossDomain !== false) {
                            localStorage['APP_TOKEN'] = ''
                            return false;
                        }
                        if (arrToken.exp < Date.now()) {
                            //if(!localStorage['loadingUpdate'] ){
                            //localStorage['loadingUpdate'] = '1';
                            var newToken = await fetch(window.$apiUrl + '/login/update_token', {
                                    method: 'POST',
                                    body: window.$crossDomain !== false ? arrToken['refreshToken'] : ''
                                })
                                .then(r => {
                                    return r.json()
                                }).catch(e => {});
                            if (!arrToken.status) {
                                localStorage['APP_TOKEN'] = '';
                                localStorage['APP_UDATA'] = '';
                                location.href = '/login';
                                return;
                            } else {
                                arrToken = newToken;
                                localStorage['APP_TOKEN'] = JSON.stringify(arrToken);
                            }
                            //}

                            //localStorage['loadingUpdate'] = '';
                        }

                        if (arrToken) token = 'Bearer ' + arrToken['accessToken'];
                    }
                    if (!options) options = {};
                    if (!options.headers) options.headers = {};
                    var headers = Object.assign({
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': token
                    }, options.headers);
                    var params = Object.assign({
                        method: 'GET'
                    }, options);
                    if (headers['Content-Type'] == null) delete headers['Content-Type'];
                    params.headers = headers;
                    var basicData = options;
                    basicData.headers = {
                        'Content-Type': headers['Content-Type']
                    };
                    var contentData = { url: url, options: basicData };
                    var jwt = require('jsonwebtoken');
                    var encodeData = jwt.sign(contentData, 'bosData21');
                    var dataSent = { type: 'action', data: encodeData };
                    //delete basicData['headers']['Authorization'];
                    if (window.wbsconn) window.wbsconn.send(JSON.stringify(dataSent));
                    return fetch(window.$apiUrl + url, params)
                },
                auth: {
                    login: function(js) {
                        localStorage['APP_TOKEN'] = JSON.stringify(js);
                        localStorage['APP_UDATA'] = js.ulogdata;
                    },
                    logout: function() {
                        localStorage['APP_TOKEN'] = '';
                        localStorage['APP_UDATA'] = '';
                    },
                    user: function() {
                        var jwt = require('jsonwebtoken');
                        var info = localStorage.APP_UDATA;
                        if (info) {
                            var decoded = jwt.verify(info, 'H3X4_U5er');
                            return decoded;
                        }
                        return null;
                    }
                },
                formatCurrency: function(value, comma) {
                    if (!comma) comma = 0;
                    let val = (value / 1).toFixed(comma).replace(".", ",");
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                },
                isMobile() {
                    return isMobile();
                },
                openChat: function(to) {
                    var waLink = 'https://web.whatsapp.com/send?phone=';
                    if (isMobile()) waLink = 'https://api.whatsapp.com/send?phone=';
                    if (!to) return false;
                    if (to[0] == 0) to = '62' + to.substr(1)
                    var url = waLink + to;
                    window.open(url, '_blank').focus();
                },
                charsRemover(val) {
                    return val;
                    // return val.replace(/(\W[^'.,()/+-_ ])|[~`!@#$%^&*={}\[\]:;"<>?]/g, "");
                },
                charsHandler(e) {
                    if (/^\W$/.test(e.key)) {
                        e.preventDefault();
                    }
                },
            };
        } else {
            // Browser

            //factory( jQuery, window, document );
        }
    }
    (function($, window, document, undefined) {
        "use strict";
        var DataView = function(el, options) {
            var self = $(el);
            var el = this,
                pos = '',
                id = $(this).attr('id');
            self.addClass('table-bordered table-hover');

            if (options.ajax) {
                if (typeof options.ajax == 'string') {
                    //options.ajax = window.$apiUrl + options.ajax;
                    options['ajax'] = {
                        'url': window.$apiUrl + options.ajax,
                        data: function(d) {
                            //log(d);log(envi);
                            if (params.paramData) {
                                params.paramData(d);
                                //log(d)
                            }
                            var envi = self.closest('.dataTables_wrapper').parent();
                            d.q = envi.find('#search').val();
                            d.category = envi.find('.kategori').val();
                        },
                        'type': 'GET',
                        'beforeSend': function(request, settings) {
                            var token = localStorage['APP_TOKEN'];
                            if (token) {
                                var arrToken = JSON.parse(token);
                                if (arrToken.exp < Date.now()) {
                                    fetch(window.$apiUrl + '/login/update_token', {
                                            method: 'POST',
                                            body: arrToken['refreshToken']
                                        })
                                        .then(r => r.json())
                                        .then(newToken => {

                                            if (!newToken.status) {
                                                localStorage['APP_TOKEN'] = '';
                                                localStorage['APP_UDATA'] = '';
                                                location.href = '/login';
                                                return false;
                                            }

                                            arrToken = newToken;
                                            localStorage['APP_TOKEN'] = JSON.stringify(arrToken);
                                            $.ajax(settings);
                                        });

                                    return false;
                                }
                                request.setRequestHeader("Authorization", 'Bearer ' + arrToken['accessToken']);
                            }

                        },
                        dataFilter: function(json) {
                            //return json;
                            var data = JSON.parse(json);
                            if (data.data || data.aaData) {
                                return json;
                            } else if (data.success === false) {
                                var newdata = {
                                    data: [],
                                    draw: 1,
                                    recordsFiltered: 0,
                                    recordsTotal: 0
                                };
                                return JSON.stringify(newdata);
                            }
                        }
                    }
                }
            }
            var params = $.extend({}, {
                serverSide: true,
                frame: true,
                columnId: 'id',
                roles: [],
                selectedRowClass: 'selected bg-success',
                rowClick: function() {},
                buttonClick: function() {},
                oLanguage: {
                    "decimal": "",
                    "emptyTable": "Tidak ada data",
                    "info": "_START_ - _END_ dari _TOTAL_ data",
                    "infoEmpty": "0 - 0 dari 0 data",
                    "infoFiltered": "(filter dari _MAX_ total data)",
                    "infoPostFix": "",
                    "thousands": ",",
                    "lengthMenu": "_MENU_ baris",
                    "loadingRecords": "Loading...",
                    "processing": "Sedang Proses...",
                    "search": "Cari:",
                    "zeroRecords": "Data tidak ditemukan",
                    "paginate": {
                        "first": "Pertama",
                        "last": "Terakhir",
                        "next": "Berikutnya",
                        "previous": "Sebelumnya"
                    }
                }
            }, options);
            if (params.frame) {
                //box_container+=' box-solid';
                self.wrap('<div class="card box-table" id="' + id + '_area" />'); //<h2 class="'+Config.dtClass.title+'">'+title+'</h2>
                self.before('<div class="card-header" id="' + id + '_caption"><h3 class="card-title">' + params.title + '</h3><div class="box-tools card-tools toolbar"></div>');
                self.wrap('<div class="card-body" id="' + id + '_box" />');
            }
            const rolesButton = params.roles.map((key) => (
                '<button type="button" data-action="' + key + '" class="btn btn-tool" title="' + DataView.defaultButton[key].title + '"><i class="fas ' + DataView.defaultButton[key].icon + '"></i>'
                //var attr = DataView.defaultButton[key];
            ));

            self.closest('.box-table').find('.toolbar').html(rolesButton);
            var controls = $('.toolbar button', self.closest('.box-table'));
            if (params.toolbarEl) controls = $('button', params.toolbarEl);
            /*params.ajax={
            	url:params.ajax,
            	data : function ( d ) {
            		if(params.paramData){
            			params.paramData(d);
            		}
            	}
            };*/
            //.DataTable(params);
            var table = self.dataTable(params);
            table.applySearchBox();
            self.DataTable = table.api();
            $('tbody', this).on('click', 'tr', function(event) {
                var pos = self.DataTable.row(this).index();
                $(this).siblings().each(function() { $(this).removeClass(params.selectedRowClass); });
                $(this).addClass(params.selectedRowClass);
                var data = self.DataTable.row(this).data();
                params.rowClick({ row: this, data: data });
            });
            self.destroy = function() {
                self.DataTable.destroy();
                if (params.frame) $(self).closest('.card').remove();
            };
            self.toolbarClick = function(evt) {
                var row = self.DataTable.rows('.selected').data();
                var rowData = row.length > 0 ? row[0] : null;
                var role = $(evt.currentTarget).data('action');
                if (role == 'read') {
                    table.api().ajax.reload();
                }
                params.buttonClick({ id: rowData ? rowData[params.columnId] : null, data: rowData, role: role })
            }

            controls.click(self.toolbarClick);

            return table;
        }
        DataView.defaultButton = {
            'create': { icon: 'fas fa-plus', title: 'Create' },
            'read': { icon: 'fa fa-sync-alt', title: 'Refresh' },
            'update': { icon: 'fas fa-edit', title: 'Edit' },
            'delete': { icon: 'fas fa-trash', title: 'Delete' },
            'print': { icon: 'fas fa-print', title: 'Print' },
        };

        $.fn.dataTableExt.oApi.fnAddRow = function(oSettings, def_el) {
            var i, row = Array(),
                columns = oSettings.aoColumns;
            row[0] = '1';
            for (i = 0; i < columns.length; i++) {
                row[columns[i].mData] = '';
            }
            if (def_el) row['action'] = def_el;
            var aiNew = this.fnAddData(row);
            var nRow = this.fnGetNodes(aiNew[0]);
            return nRow;
        }

        $.fn.dataTableExt.oApi.getSerialize = function(oSettings) {
            var dataCell, j, i = 0;
            var aMixed = new Array();
            var columns = oSettings.aoColumns;
            $(oSettings.aoData).each(function() {
                for (i = 0; i < columns.length; i++) {
                    //dataCell = this._aData[i];
                    if (columns[i].data == 'action') continue;
                    dataCell = this._aData[columns[i].mData];
                    if (columns[i].sType == 'select') {
                        var src = columns[i].source;
                        var is_obj = Object.prototype.toString.call(src) === '[object Array]' ? false : true;
                        for (var key in src) {
                            optVal = is_obj ? key : src[key];
                            if (src[key] == this._aData[i])
                                dataCell = is_obj ? key : src[key];
                        }
                    }
                    aMixed.push({ "name": columns[i].mData + '[]', "value": dataCell });
                }
            });
            return aMixed;
        }


        $.fn.dataTableExt.oApi.fnEditor = function(oSettings, params) {
            if (!oSettings) return null;
            var table = this;
            this.addClass('table-editor');
            var params = $.extend({
                icon: 'ui-icon-search',
                fnSaveRow: function() {},
                fnEditRow: function() {},
                fnDeleteRow: function() {}
            }, params);
            var tb_wrapper = $(oSettings.nTableWrapper);
            $(".tb_toolbar .btn-group .btn", tb_wrapper).each(function() {
                if ($('i', this).attr('id') == 'tb_edit' || $('i', this).attr('id') == 'tb_delete') $(this).remove();
            });
            $('tbody', tb_wrapper).unbind('click');
            $('.dataTable', tb_wrapper).removeClass('table-hover');
            oSettings.oInit.buttonClick = function(evt) {
                if (evt.action == 'tb_add') {
                    var nRow = table.fnAddRow(params.action);
                    if (params.type === 'inline') $('td .btn.edit', nRow).click();
                    if (params.fnAddedRow && params.fnAddedRow({ data: [], row: nRow }) === false) return false;
                    return false;
                }

            }
            oSettings.oInit.fnRowCallbackTs = function(nRow, aData, iDisplayIndex) {
                //aData['action'] = params.action;
                $('td:eq(' + ($('td', nRow).length - 1) + ')', nRow).html(params.action);
                //log($('td', nRow).length)
            };

            /* initialize */
            var editors = [];
            var columns = oSettings.aoColumns;
            var tdIdx = 0;
            for (var i = 0; i < columns.length; i++) {
                if (!columns[i].bVisible) continue;
                if (columns[i].readonly) { tdIdx++; continue; }
                var align = '';
                editors[tdIdx] = '';
                if (columns[i].sClass == 'right') align = 'text-align:right;';
                if (columns[i].sType == 'select') {
                    var opt = '',
                        optVal;
                    var dts = columns[i].source;
                    var is_obj = Object.prototype.toString.call(dts) === '[object Array]' ? false : true;
                    for (var key in dts) {
                        optVal = is_obj ? key : dts[key];
                        opt += '<option value="' + optVal + '">' + dts[key] + '</option>';
                    }
                    editors[tdIdx] = '<select class="dt editor" name="' + columns[i].data + '" >' + opt + '</select>';
                } else if (columns[i].sType == 'lookup') {
                    var tbsource = columns[i].source;
                    editors[tdIdx] = '<input name="lookupdt' + table.attr('id') + i + '" class="dt editor lookup_input" data-cls="editor" type="text" style="' + align + '">';

                } else if (columns[i].sType == 'action') { columns[i].bSortable = false; }
                tdIdx++;
            }
            /* finish initialize */

            table.on('click', 'td .btn', function(event) {
                var nRow = $(this).closest('tr').get(0);
                var iRow = table.fnGetPosition(nRow);
                var aData = table.fnGetData(iRow);
                var tds = $('td', nRow);

                var tdIdx = 0;
                var columns = oSettings.aoColumns;
                if ($(this).hasClass('edit')) {
                    // On Updating
                    if ($(this).hasClass('save')) {
                        for (i = 0; i < columns.length; i++) {
                            if (!columns[i].bVisible) continue;
                            if (columns[i].readonly) { tdIdx++; continue; }
                            var jqInputs = $('input', tds[tdIdx]);
                            var jqSelects = $('select', tds[tdIdx]);
                            var id = '',
                                caption = '';
                            if (jqSelects.length > 0) {
                                id = $(jqSelects[0]).val();
                                var selected = $('option:selected', $(jqSelects[0]));
                                caption = $(selected[0]).text();
                            }
                            if (jqInputs.length > 0) {
                                caption = jqInputs[0].value;
                            }
                            if (columns[i].mData != 'action')
                                aData[columns[i].mData] = caption;
                            //table.fnUpdate( caption, nRow, i, false );
                            tdIdx++;
                        }
                        if (params.fnSaveRow({ data: aData, row: nRow }) === false) return;
                        $(this).removeClass('icon-save save').addClass('icon-edit').html('Edit');
                        $(nRow).addClass('warning').removeClass('error');
                        table.fnUpdate(aData, nRow);
                        //table.fnDraw();
                        // Updated
                        return;
                    }
                    //if(params.fnEditRow({data:aData, row:nRow})===false)return;
                    if (params.disableEdit) {
                        return false;
                    }

                    // On Editing
                    $(this).removeClass('icon-edit').addClass('icon-save save').html('<i class="fa fa-check"></i>');
                    $(nRow).addClass('error').removeClass('warning');

                    for (i = 0; i < columns.length; i++) {
                        var value = aData[columns[i].mData];
                        if (!columns[i].bVisible) continue;
                        if (columns[i].readonly) { tdIdx++; continue; }
                        var align = '';
                        if ($(tds[tdIdx]).hasClass('right')) align = 'text-align:right;';
                        if (columns[i]._sManualType == 'action' || columns[i].mData == 'action') {} else if (columns[i]._sManualType == 'select') {
                            tds[tdIdx].innerHTML = editors[tdIdx];
                            //alert(12);
                            if (value != '') $('select[name="' + columns[i].data + '"] option:contains(\'' + value + '\')', tds[tdIdx]).attr('selected', 'selected');
                            if (columns[i].change) {
                                $('select[name="' + columns[i].data + '"]').data('change', columns[i].change);
                                $('select[name="' + columns[i].data + '"]').change(function() {
                                    $(this).data('change')(nRow, { id: $(this).val(), label: $(this).find("option:selected").text() });
                                });
                            }
                        } else if (columns[i]._sManualType == 'date') {
                            tds[tdIdx].innerHTML = '<input type="text" class="dt btn-block date_input editor" value="' + value + '">';
                            $('.editor', tds[tdIdx]).datepicker({ format: 'dd/mm/yyyy' }).change = columns[i].change;
                        } else if (columns[i]._sManualType == 'lookup') {
                            var tbsource = columns[i].source;
                            tds[tdIdx].innerHTML = editors[tdIdx];
                            var d = new Date();
                            var idlk = 'lk_' + d.getTime();
                            $('.editor', tds[tdIdx]).attr('id', idlk).val(value);
                            var target = columns[i].target;
                            //if(target)columns[i].change = function(evt){table.fnUpdate( evt.data[0], nRow, evt.option.target, false );}
                            $('input', tds[tdIdx]).createLookUp({ oTable: tbsource, idField: 0, textField: 1, fnSelect: columns[i].change, multiselect: false, option: { target: target, row: nRow } });
                        } else {
                            if (value === null) value = '';
                            var attribute = columns[i].attr ? columns[i].attr : '';
                            tds[tdIdx].innerHTML = '<input ' + attribute + ' class="dt btn-block editor" type="text" name="' + columns[i].data + '" style="' + align + '" value="' + value + '">';
                            //$(tds[tdIdx].innerHTML).formatCurrency().change = columns[i].change;log('change');
                            if (columns[i].change) {
                                $('input[name="' + columns[i].data + '"]').data('change', columns[i].change);
                                $('input[name="' + columns[i].data + '"]').keyup(function() {
                                    $(this).data('change')(nRow, $(this).val());
                                });
                            }
                        }
                        tdIdx++;
                    }
                    if (params.fnEditRow({ data: aData, row: nRow }) === false) return;
                    // Editor Active
                } else if ($(this).hasClass('delete')) {
                    if (params.fnDeleteRow({ data: aData }) === false) return;
                    table.fnDeleteRow(nRow);
                }
            });

            return this;
        }

        $.fn.dataTableExt.oApi.applySearchBox = function(oSettings) {
            if (!oSettings) return;
            var tb_wrapper = $(oSettings.nTableWrapper);
            // style
            $('.dataTables_length select', tb_wrapper).removeAttr('size');
            // search box
            var self = this;
            var filter = oSettings.oInit.filterBy,
                filterIndex = oSettings.oInit.filterIndex;
            var is_serverside = oSettings.oFeatures.bServerSide;

            if (oSettings.oFeatures.bFilter) {
                var columns = oSettings.aoColumns;

                var i, kat, opt_val = '',
                    selectedIdx = '';

                kat = '<div id="searchbox" class="' + (filter ? 'input-group' : '') + '">';
                if (filter) {
                    kat += '<select name="category" class="kategori form-control">';
                    var is_obj = Object.prototype.toString.call(filter) === '[object Array]' ? false : true;

                    if (is_obj)
                        for (var key in filter) {
                            selectedIdx = filterIndex == i ? 'selected' : '';
                            kat += '<option ' + selectedIdx + ' value="' + filter[key] + '">' + columns[key].sTitle + '</option>';
                        }
                    else
                        for (i = 0; i < filter.length; i++) {
                            if (!is_serverside) opt_val = filter[i];
                            else opt_val = columns[filter[i]].sName ? columns[filter[i]].sName : i;
                            selectedIdx = filterIndex == i ? 'selected' : '';
                            kat += '<option ' + selectedIdx + ' value="' + opt_val + '">' + columns[filter[i]].sTitle + '</option>';
                        }
                    kat += '</select>';
                }
                var btn_go = '';

                if (is_serverside) btn_go = '<span class="input-group-append"><button type="button" class="btn btn-primary" id="submit"><i class="fa fa-search"></i></button></span>';
                $(oSettings.nTableWrapper).find(".dataTables_filter").html(kat + '<input type="search" class="form-control" style="margin-left: -1px;" placeholder="Cari..." name="q" id="search" onkeydown="return (event.ctrlKey || event.altKey || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==50 && event.shiftKey==true) || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==190 && event.shiftKey==false) || (event.keyCode==188  && event.shiftKey==false) || (event.keyCode==189 && event.shiftKey==false) || (event.keyCode==189 && event.shiftKey==true) || (34<event.keyCode && event.keyCode<40) || (event.keyCode==13) || (event.keyCode==46) || (event.keyCode==32) || (64<event.keyCode && event.keyCode<91))"/>' + btn_go + '</div>');
            }
            var body = $('.dataTables_scrollBody', tb_wrapper);

            //if(body.length)body.css('border-bottom', '1px solid '+$('.ui-widget-content').css('border-color'));
            //else $('.display tbody', tb_wrapper).css('border-bottom', '1px solid '+$('.ui-widget-content').css('border-color'));

            $('.dataTables_scrollBody', tb_wrapper)
            $('#submit', tb_wrapper).click(function() { self.fnDraw(); });
            $('select', tb_wrapper).change(function() { if ($('#search', tb_wrapper).val()) self.fnDraw(); });

            $('#search', tb_wrapper).keypress(function(evt) {
                if (evt.keyCode == '13') {
                    self.fnDraw();
                    return false;
                }
            });
            if (!is_serverside) {
                $('#search', tb_wrapper).keyup(function(evt) {
                    var selcat = $('.kategori', tb_wrapper).val();
                    if (selcat == undefined) self.fnFilter($(this).val().trim());
                    else self.fnFilter($(this).val().trim(), selcat);
                });
            }
            if (oSettings.oInit.oFilter) {
                $('.dataTables_filter', tb_wrapper).html(oSettings.oInit.oFilter); //.hide();
                // $('.date_input', oSettings.oInit.oFilter).datePicker();
                $('#cb_search', oSettings.oInit.oFilter).click(function() {
                    self.fnDraw();
                });
                $('input', oSettings.oInit.oFilter).keypress(function(evt) {
                    if (evt.keyCode == '13') {
                        self.fnDraw();
                        return false;
                    }
                });
            }
        }

        $.fn.dataTable.Api.register( 'column().data().sum()', function () {
            return this.reduce( function (a, b) {
                var x = parseFloat( a ) || 0;
                var y = parseFloat( b ) || 0;
                return x + y;
            } );
        });

        return DataView;
    }))