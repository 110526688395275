const routes = [{
        path: '/riwayat',
        name: 'Riwayat',
        component: () =>
            import ('@/views/Riwayat.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.history'
        }
    },
    {
        path: '/riwayat/admin',
        name: 'Riwayat Admin',
        component: () =>
            import ('@/views/RiwayatAdmin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.history'
        }
    },
    {
        path: '/riwayat/admin/all',
        name: 'Riwayat Admin',
        component: () =>
            import ('@/views/RiwayatAdmin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.history'
        }
    },
    {
        path: '/riwayat/all',
        name: 'Riwayat',
        component: () =>
            import ('@/views/Riwayat.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.history'
        }
    },
    {
        path: '/riwayat-kurir',
        name: 'Riwayat Order',
        component: () =>
            import ('@/views/RiwayatKurir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.historykurir'
        }
    },
    {
        path: '/riwayat-kurir/all',
        name: 'Riwayat Order',
        component: () =>
            import ('@/views/RiwayatKurir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.historykurir'
        }
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () =>
            import ('@/views/Payment.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/payment-admin',
        name: 'Payment BOSCOD',
        component: () =>
            import ('@/views/PaymentAdmin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/payment-admin/all',
        name: 'Payment BOSCOD',
        component: () =>
            import ('@/views/PaymentAdmin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/finance/shipper',
        name: 'Financial Report Shipper',
        component: () =>
            import ('@/views/FinanceShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/payment-shipper',
        name: 'Payment Shipper',
        component: () =>
            import ('@/views/PaymentShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.shipper'
        }
    },
    {
        path: '/payment-shipper/all',
        name: 'Payment Shipper',
        component: () =>
            import ('@/views/PaymentShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.shipper'
        }
    },
    {
        path: '/payment-shipper/retur',
        name: 'Ongkir Retur Shipper',
        component: () =>
            import ('@/views/PaymentShipperRetur.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.shipper'
        }
    },
    {
        path: '/payment-kurir',
        name: 'Payment Kurir',
        component: () =>
            import ('@/views/PaymentKurir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.kurir'
        }
    },
    {
        path: '/payment-kurir/detail/:id',
        name: 'Detail Payment Order',
        component: () =>
            import ('@/views/PaymentdetailKurir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.kurir'
        }
    },
    {
        path: '/payment-shipper/detail/:id',
        name: 'Detail Payment Order',
        component: () =>
            import ('@/views/PaymentdetailShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.shipper'
        }
    },
    {
        path: '/payment-shipper/retur/detail/:id',
        name: 'Detail Payment Order',
        component: () =>
            import ('@/views/PaymentdetailShipperRetur.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.shipper'
        }
    },
    {
        path: '/payment-admin/detail/:id',
        name: 'Detail Payment Order',
        component: () =>
            import ('@/views/PaymentdetailAdmin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/cek',
        name: 'Cek Status',
        component: () =>
            import ('@/views/Checktracking.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.tracking'
        }
    },
    {
        path: '/uploader',
        name: 'Buat Kiriman',
        component: () =>
            import ('@/views/Uploader.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/shipper',
        name: 'Shipper',
        component: () =>
            import ('@/views/Shipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.list'
        }
    },
    {
        path: '/afiliasi-shipper',
        name: 'Reward Afiliasi',
        component: () =>
            import ('@/views/ShipperAfiliasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.affiliasi'
        }
    },
    {
        path: '/afiliator',
        name: 'Afiliator',
        component: () =>
            import ('@/views/ShipperAfiliator.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.affiliasi'
        }
    },
    {
        path: '/afiliasi-shipper-detail/:id',
        name: 'Detail Komisi Affiliator',
        component: () =>
            import ('@/views/ShipperAfiliasiDetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.affiliasi'
        }
    },
    {
        path: '/afiliasi-shipper-detail',
        name: 'Program Afiliasi',
        component: () =>
            import ('@/views/ShipperAfiliasiDetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.affiliasi'
        }
    },
    {
        path: '/shipper/detail/:id',
        name: 'Shipper Status',
        component: () =>
            import ('@/views/DetailShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.status'
        }
    },
    {
        path: '/shipper/recap',
        name: 'Shipper Recap',
        component: () =>
            import ('@/views/ShipperRecap.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.list'
        }
    },
    {
        path: '/shipper/rekening',
        name: 'Request Rekening Shipper',
        component: () =>
            import ('@/views/RekeningShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.list'
        }
    },
    {
        path: '/buyer',
        name: 'Buyer',
        component: () =>
            import ('@/views/Buyer.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.admin.buyer'
        }
    },
    {
        path: '/seller',
        name: 'Alamat Pengirim',
        component: () =>
            import ('@/views/Seller.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.seller'
        }
    },
    {
        path: '/order',
        name: 'Order Group',
        component: () =>
            import ('@/views/Order.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/count/:id',
        name: 'Detail Order Group',
        component: () =>
            import ('@/views/Ordercount.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/create',
        name: 'Membuat Pesanan',
        component: () =>
            import ('@/views/Orderform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/create/single',
        name: 'Buat Pesanan',
        component: () =>
            import ('@/views/mobile/Orderform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/create/draft/:id',
        name: 'Lanjutkan Pesanan',
        component: () =>
            import ('@/views/mobile/Orderform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/import',
        name: 'Import Pesanan',
        component: () =>
            import ('@/views/Orderimport.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/edit/:id',
        name: 'Form Edit Order Group',
        component: () =>
            import ('@/views/Orderform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/orderform',
        name: 'Form Order',
        component: () =>
            import ('@/views/Orderform2.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/jurnal/generate',
        name: 'Generate Jurnal Manual',
        component: () =>
            import ('@/views/GenerateJurnal.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    {
        path: '/jurnal/generate-batch',
        name: 'Generate Jurnal Manual Batch',
        component: () =>
            import ('@/views/GenerateJurnalBatch.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () =>
            import ('@/views/Profile.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/kustom-kurir',
        name: 'Pengaturan Kurir',
        component: () =>
            import ('@/views/KustomKurir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true
        }
    },
    {
        path: '/pengumuman',
        name: 'Pengumuman',
        component: () =>
            import ('@/views/Pengumuman.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'public.announcement'
        }
    },
    {
        path: '/settings/membertype',
        name: 'Jenis Member',
        component: () =>
            import ('@/views/master/Membertype.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.type'
        }
    },
    {
        path: '/settings/banklist',
        name: 'BOSCOD Rekening',
        component: () =>
            import ('@/views/master/Banklist.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.admin.rekening'
        }
    },
    {
        path: '/afiliasi',
        name: 'Afiliasi',
        component: () =>
            import ('@/views/master/Afiliasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.afiliasi'
        }
    },
    {
        path: '/settings/membertype/detail/:id',
        name: 'Detail Membership',
        component: () =>
            import ('@/views/master/DetailMembertype.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.type'
        }
    },
    {
        path: '/settings/courier',
        name: 'Data Kurir',
        component: () =>
            import ('@/views/master/Courier.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.courier.list'
        }
    },
    {
        path: '/settings/courier_service',
        name: 'Data Service Kurir',
        component: () =>
            import ('@/views/master/Courierservice.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.courier.list'
        }
    },
    {
        path: '/settings/origin',
        name: 'Data List Origin',
        component: () =>
            import ('@/views/master/Origin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.courier.origin'
        }
    },
    {
        path: '/settings/destination',
        name: 'Kode Alamat',
        component: () =>
            import ('@/views/master/Destination.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.courier.destination'
        }
    },
    {
        path: '/settings/destination/jne',
        name: 'Kode Alamat JNE',
        component: () =>
            import ('@/views/master/DestinationJne.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.courier.destination'
        }
    },
    {
        path: '/settings/destination/idx',
        name: 'Kode Alamat ID EXPRESS',
        component: () =>
            import ('@/views/master/DestinationIdx.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.courier.destination'
        }
    },
    {
        path: '/settings/user',
        name: 'Data Admin Shipper',
        component: () =>
            import ('@/views/master/UserCS.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.cs.list'
        }
    },
    {
        path: '/settings/shipper/address',
        name: 'Data Gudang All Shipper',
        component: () =>
            import ('@/views/master/GudangShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.list'
        }
    },
    {
        path: '/order/detail/:id',
        name: 'Detail Order',
        component: () =>
            import ('@/views/Orderdetail.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/order/edititem/:id',
        name: 'Edit Order',
        component: () =>
            import ('@/views/Orderedit.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.order.form'
        }
    },
    {
        path: '/saldo',
        name: 'Saldo',
        component: () =>
            import ('@/views/Saldo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.saldo.form'
        }
    },
    {
        path: '/saldo/approval',
        name: 'Konfirmasi Saldo',
        component: () =>
            import ('@/views/Saldoapprove.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/saldo/topup/approval',
        name: 'Konfirmasi Topup Saldo',
        component: () =>
            import ('@/views/TopupApprove.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/saldo/withdraw/approval',
        name: 'Konfirmasi Withdraw Saldo',
        component: () =>
            import ('@/views/WithdrawApprove.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/saldo/withdraw/retained',
        name: 'Daftar Paket Dana Tertahan',
        component: () =>
            import ('@/views/RiwayatTertahan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.saldo.form'
        }
    },
    {
        path: '/rekonsiliasi',
        name: 'Rekonsiliasi',
        component: () =>
            import ('@/views/Rekonsiliasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.rekonsiliasi.list'
        }
    },
    {
        path: '/user/kurir',
        name: 'List User Kurir',
        component: () =>
            import ('@/views/master/UserCourier.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.courier.user'
        }
    },
    {
        path: '/saldo/shipper',
        name: 'List Daftar Saldo Shipper',
        component: () =>
            import ('@/views/ListSaldo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/saldo/shipper/:id',
        name: 'Rincian Saldo Shipper',
        component: () =>
            import ('@/views/RincianSaldo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.payment.admin'
        }
    },
    {
        path: '/dashboard-admin',
        name: 'Dashboard Admin',
        component: () =>
            import ('@/views/DashboardAdmin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.dashboard.admin'
        }
    },
    // {
    //     path: '/dashboard-shipper',
    //     name: 'Dashboard Shipper',
    //     component: () =>
    //         import ('@/views/DashboardShipper.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true,
    //         code: 'cod.dashboard.shipper'
    //     }
    // },
    // {
    //     path: '/dashboard-cs',
    //     name: 'Dashboard',
    //     component: () =>
    //         import ('@/views/DashboardShipperCs.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true,
    //         code: 'cod.dashboard.shipper'
    //     }
    // },
    {
        path: '/dashboard/finance',
        name: 'Financial Report',
        component: () =>
            import ('@/views/DashboardShipperFinance.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.dashboard.shipper'
        }
    },
    {
        path: '/dashboard-cs',
        name: 'Dashboard',
        component: () =>
            import ('@/views/DashboardShipperCs.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.dashboard.shipper'
        }
    },
    {
        path: '/dashboard-kurir',
        name: 'Dashboard Kurir',
        component: () =>
            import ('@/views/DashboardKurir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.dashboard.kurir'
        }
    },
    {
        path: '/settings/rekening',
        name: 'Info Rekening',
        component: () =>
            import ('@/views/Rekening.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.user.rekening'
        }
    },
    {
        path: '/settings/tickets',
        name: 'List Ticket',
        component: () =>
            import ('@/views/ListTicket.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/settings/tickets/all',
        name: 'List Ticket',
        component: () =>
            import ('@/views/ListTicket.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/settings/tickets/claim',
        name: 'List Claim',
        component: () =>
            import ('@/views/ListClaim.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/settings/tickets/claimcourier',
        name: 'List Claim',
        component: () =>
            import ('@/views/ListClaimCourier.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.tickets'
        }
    },
    {
        path: '/rekonsiliasi/shipper/upload',
        name: 'Upload Rekonsiliasi Shipper',
        component: () =>
            import ('@/views/FormRekonShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.tickets'
        }
    },
    {
        path: '/rekonsiliasi/kurir/upload',
        name: 'Upload Rekonsiliasi Kurir',
        component: () =>
            import ('@/views/FormRekonKurirNew.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.tickets'
        }
    },
    {
        path: '/updatetracking',
        name: 'Update Tracking Manual Batch',
        component: () =>
            import ('@/views/FormRekonKurirCopy.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.tickets'
        }
    },
    {
        path: '/blast-notif',
        name: 'Blast Notification',
        component: () =>
            import ('@/views/FormBlastNotif.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.notification'
        }
    },
    {
        path: '/list-notif',
        name: 'Notifikasi',
        component: () =>
            import ('@/views/ListNotifikasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
        }
    },
    {
        path: '/report/hutang/kurir/upload',
        name: 'Upload Hutang Usaha (Kurir)',
        component: () =>
            import ('@/views/FormHutangKurir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.hutang'
        }
    },
    {
        path: '/cek-ongkir',
        name: 'Cek Ongkir',
        component: () =>
            import ('@/views/CekOngkir.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.ongkir'
        }
    },
    {
        path: '/pages/:id',
        name: 'Informasi',
        component: () =>
            import ('@/views/Informasi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.news'
        }
    },
    {
        path: '/report/bukubesar',
        name: 'Laporan Buku Besar',
        component: () =>
            import ('@/views/BukuBesar.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.bukubesar'
        }
    },
    {
        path: '/report/trialbalance',
        name: 'Laporan Trial Balance',
        component: () =>
            import ('@/views/TrialBalance.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.trialbalance'
        }
    },
    {
        path: '/report/bospay',
        name: 'Laporan Bospay',
        component: () =>
            import ('@/views/Bospay.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.bospay'
        }
    },
    {
        path: '/report/anomali-bospay',
        name: 'Anomali Bospay',
        component: () =>
            import ('@/views/AnomaliBospay.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.anomali'
        }
    },
    {
        path: '/report/hutang/kurir',
        name: 'Laporan Hutang Usaha (Kurir)',
        component: () =>
            import ('@/views/HutangUsaha.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.hutang'
        }
    },
    {
        path: '/report/hutang/shipper',
        name: 'Laporan Hutang COD (Shipper)',
        component: () =>
            import ('@/views/HutangCod.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.hutang'
        }
    },
    {
        path: '/report/piutang',
        name: 'Laporan Piutang',
        component: () =>
            import ('@/views/Piutang.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.piutang'
        }
    },
    {
        path: '/report/piutang-new',
        name: 'Laporan Piutang',
        component: () =>
            import ('@/views/PiutangNew.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.piutang'
        }
    },
    {
        path: '/report/ppn-masukan',
        name: 'Laporan PPN Masukan',
        component: () =>
            import ('@/views/PpnMasukan.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.ppn'
        }
    },
    {
        path: '/report/ppn-keluaran',
        name: 'Laporan PPN Keluaran',
        component: () =>
            import ('@/views/PpnKeluaran.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.ppn'
        }
    },
    {
        path: '/report/jurnal-entri',
        name: 'Laporan Jurnal Entri',
        component: () =>
            import ('@/views/Jurnalentri.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    {
        path: '/report/jurnal-group',
        name: 'Laporan Jurnal Entri (Group)',
        component: () =>
            import ('@/views/Jurnalgroup.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    {
        path: '/report/form-jurnal',
        name: 'Form Jurnal Entri',
        component: () =>
            import ('@/views/Jurnalform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    // {
    //     path: '/report/close-jurnal',
    //     name: 'Form Tutup Buku',
    //     component: () =>
    //         import ('@/views/JurnalClose.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true,
    //         code: 'cod.report.jurnal'
    //     }
    // },
    {
        path: '/report/close-jurnal',
        name: 'Form Tutup Buku',
        component: () =>
            import ('@/views/JurnalClose.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    {
        path: '/report/form-jurnal/:id',
        name: 'Form Edit Jurnal Entri',
        component: () =>
            import ('@/views/Jurnalform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    {
        path: '/report/form-jurnal-balik/:id',
        name: 'Form Jurnal Balik',
        component: () =>
            import ('@/views/Jurnalbalikform.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    {
        path: '/report/labarugi',
        name: 'Laporan Laba Rugi',
        component: () =>
            import ('@/views/Labarugi.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.laba'
        }
    },
    {
        path: '/report/neraca',
        name: 'Laporan Posisi Keuangan / Neraca',
        component: () =>
            import ('@/views/Neraca.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.neraca'
        }
    },
    {
        path: '/info',
        name: 'Master Info',
        component: () =>
            import ('@/views/master/Info.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.info'
        }
    },
    {
        path: '/info/create',
        name: 'Form Info',
        component: () =>
            import ('@/views/FormInfo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.info'
        }
    },
    {
        path: '/info/:id',
        name: 'Info',
        component: () =>
            import ('@/views/PreviewInfo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.info'
        }
    },
    {
        path: '/info/edit/:id',
        name: 'Master Info',
        component: () =>
            import ('@/views/FormInfo.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            // code: 'cod.info'
        }
    },
    // {
    //     path: '/informasi/ketentuan',
    //     name: 'Info',
    //     component: () =>
    //         import ('@/views/PreviewInformasi.vue'),
    //     meta: {
    //         layout: 'AppLayoutAdmin',
    //         requiresAuth: true,
    //         // code: 'cod.info'
    //     }
    // },
    {
        path: '/report/kewajaran',
        name: 'Kewajaran',
        component: () =>
            import ('@/views/Kewajaran.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.kewajaran'
        }
    },
    {
        path: '/shipper/verification',
        name: 'List Verification KTP',
        component: () =>
            import ('@/views/VerificationKtp.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.verification'
        }
    },
    {
        path: '/shipper/verification/:id',
        name: 'Detail Verification KTP',
        component: () =>
            import ('@/views/DetailVerificationKtp.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.verification'
        }
    },
    {
        path: '/verification',
        name: 'Verifikasi Akun',
        component: () =>
            import ('@/views/VerifyShipper.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.verify.shipper'
        }
    },
    {
        path: '/settings/pin',
        name: 'Buat PIN otorisasi',
        component: () =>
            import ('@/views/SetPin.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.pin.shipper'
        }
    },
    {
        path: '/shipper/membership',
        name: 'Membership',
        component: () =>
            import ('@/views/Membership.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.membership'
        }
    },
    {
        path: '/shipper/membership/history',
        name: 'History Membership',
        component: () =>
            import ('@/views/MembershipHistory.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.membership'
        }
    },
    {
        path: '/shipper/courier/generate',
        name: 'Generate Kurir Shipper',
        component: () =>
            import ('@/views/ShipperCourierGenerate.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.shipper.list'
        }
    },
    {
        path: '/rekonsiliasi/winpay',
        name: 'Upload Rekonsiliasi Invoice Winpay',
        component: () =>
            import ('@/views/FormRekonWinpay.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
    {
        path: '/report/winpay',
        name: 'Laporan Winpay Payment Gateway',
        component: () =>
            import ('@/views/ReportWinpay.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.report.jurnal'
        }
    },
]

export default routes