import Vue from 'vue'
import App from './App.vue'
import router from './router'
import AppLayout from '@/layouts/AppLayout'
import VueLazyLoad from 'vue-lazyload'
import Vuelidate from 'vuelidate'
import GoogleAuth from '@/libs/google.js'
import VueGtag from "vue-gtag";
//import $ from 'jquery';
//window.$ = window.jQuery = $;

import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'overlayscrollbars/js/jquery.overlayScrollbars.js'
//console.log(dt)
require('@/libs/adminlte')
require('@/libs/bootstrap.min')
require('@/libs/gtag')
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.css';
import '@/assets/style.css'

//Vue.component('AppLayout', AppLayout)
Vue.use(Vuelidate)
Vue.use(VueLazyLoad)
const gauthOption = {
    clientId: '215660440774-20qs3kvga8iunm9i9hqps156mvedn3cv.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}
Vue.use(GoogleAuth, gauthOption);
Vue.use(VueGtag, {
  config: { id: "G-CY6T6JW83X" }
});

Vue.config.productionTip = false
Vue.config.enableChat = true
window.$apiUrl = 'http://localhost/boscodhexa/boscod/app/backend/api';
// window.$apiUrl = 'http://localhost:9001';
//window.$apiUrl = 'https://demo.hexaintegra.com/boscod/app/backend/api';
//window.$apiUrl = 'https://cod.hexaintegra.com/api';
//window.$apiUrl = 'https://demo.boscod.com/api';
window.$crossDomain = true;
if (process.env.NODE_ENV === 'production') {
    var url = process.env.VUE_APP_ENDPOINT;
    if (url) window.$apiUrl = url;
    else window.$apiUrl = 'https://app.boscod.com/api';
    window.$crossDomain = false;
}

Vue.config.appHost = process.env.VUE_APP_HOST;
console.log('host', process.env.VUE_APP_HOST);
/*
// Initialize Firebase
import firebase from "firebase/app";
import "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyCfw8abe8FGf7mT6mZn28XKop7Y6Mtow5c",
  authDomain: "boscod-app.firebaseapp.com",
  projectId: "boscod-app",
  storageBucket: "boscod-app.appspot.com",
  messagingSenderId: "215660440774",
  appId: "1:215660440774:web:5fbf3d8abc46d4f7d43bc7",
  measurementId: "G-T063T54YM5"
};
firebase.initializeApp(firebaseConfig);

navigator.serviceWorker
  .register("fb-messaging-sw.js")
  .then((registration) => {
    const messaging = firebase.messaging();
    messaging.useServiceWorker(registration);
    messaging.getToken()
  .then(function(currentToken) {
    if (currentToken) {
      console.log('reg',currentToken);
      //updateUIForPushEnabled(currentToken);
      //console.log(currentToken);
    } else {
      // Show permission request.
      console.log('No Instance ID token available. Request permission to generate one.');
      // Show permission UI.
      //updateUIForPushPermissionRequired();
      //setTokenSentToServer(false);
    }
  })
  .catch(function(err) {
    console.log('An error occurred while retrieving token. ', err);
    //showToken('Error retrieving Instance ID token. ', err);
    //setTokenSentToServer(false);
  });
  })
  .catch((err) => {
    console.log(err);
  });
*/
//Vue.prototype.jQuery = jQuery
Vue.prototype.loadMenu = false;
new Vue({
    router,
    render: h => h(App)
}).$mount('#app');