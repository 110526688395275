<template>
  <nav id="sidemenu" class="main-header navbar navbar-expand navbar-white navbar-light justify-content-between">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>
      </li>
    </ul>

    <!-- <NewNumber v-on:change="handleCustomChange" v-bind:params="{
      phoneNumber: form.tel,
      otpType: formNewNumber.otpType,
      countShowOTP: countShowOTP,
      shipper: form.id,
    }" /> -->

    <OneTimePassword v-on:change="handleCustomChange" v-bind:params="{
			phoneNumber: form.tel,
			otpType: form.otpType,
			countShowOTP: countShowOTP,
			}" />

    <ul class="navbar-nav overflow-hidden">
      <li class="nav-item overflow-hidden">
        <router-link to="/saldo" class="btn btn-success overflow-hidden mw-100" style="text-overflow: ellipsis;" v-if="isShipper">
          <span class="d-none d-sm-inline-block mr-1">Bospay</span> {{ infoSaldo }}
        </router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block my-auto pl-3" v-if="isShipper">
        <VTooltip theme="boscod">
          <button type="button" class="btninfo"><i class="fas fa-info-circle"></i></button>

          <template #popper>
            <table class="table table-borderless" style="font-size: 13px;width: 100%;">
              <tbody>
                <tr v-for="(item) in infostatus">
                  <td class="text-left">{{ item.prefix }}</td>
                  <td class="text-left">{{ (item.status == '1' ? 'Plus' : 'Reguler') }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </VTooltip>
      </li>
      <li class="nav-item d-flex align-items-center" v-if="isShipper">
        <popper
          trigger="clickToToggle"
          :options="{
            placement: 'bottom',
            modifiers: {
              offset: { offset: '0,10px' }
            }
          }">
          <div class="popper">
            <!-- 
            <div class="row p-3 border-bottom">
              <div class="col-auto pt-1 pr-3">
                <i class="fi fi-rr-bell"></i>
              </div>
              <div class="col text-left">
                <div>
                  Mohon maaf, saat ini proses pembayaran kloter 3 melalui BCA & e-wallet belum bisa diproses karena ada gangguan teknis pada Bank.
                </div>
              </div>
            </div>
            <div class="row p-3 border-bottom">
              <div class="col-auto pt-1 pr-3">
                <i class="fi fi-rr-bell"></i>
              </div>
              <div class="col text-left">
                <div>
                  IDExpress sudah normal kembali. Resi lama sudah berhasil ditracking. Jika ada kendala tracking, bisa diinfokan ke tim kami via livechat
                </div>
              </div>
            </div>
            <div class="row p-3 border-bottom">
              <div class="col-auto pt-1 pr-3">
                <i class="fi fi-rr-bell"></i>
              </div>
              <div class="col text-left">
                <div>
                  Paket dengan nomor resi IDE683002022, telah tiba di tujuan
                </div>
              </div>
            </div>
             -->
            <div class="row  p-4" v-if="!infoNotifikasi.length">
              <div class="col">
                <h1 class="text-secondary">(˚Δ˚)b</h1>
                <h6 class="text-secondary mb-0">Belum ada notifikasi terbaru, nantikan promosi dari kami.</h6>
              </div>
            </div>
            <a :href="notif.link" target="_blank" class="row p-3 border-bottom text-dark" v-for="notif in infoNotifikasi.slice(0, 3)">
              <div class="col-auto pt-1 pr-3">
                <i class="fi fi-rr-bell"></i>
              </div>
              <div class="col text-left">
                <div v-if="notif.body.length > 130">{{ notif.body.substr(0,130) + ' <small class="text-danger">&lt;sebagian teks hilang&gt;</small>' }}</div>
                <div v-else>{{ notif.body }}</div>
              </div>
            </a>
            <div class="row p-3" v-if="infoNotifikasi.length && true" @click="$router.push({ name: 'Notifikasi' })">
              <div class="col h6 mb-0 text-primary font-weight-bold">Lihat Semua Notifikasi</div>
            </div>
          </div>
      
          <button slot="reference" class="btn btn-sm btn-circle btn-light rounded-circle mx-2 p-0 position-relative popper-button" alt="d-flex justify-content-center align-items-center">
            <i class="fi fi-rr-bell h5 position-relative popper-icon"></i>
            <span class="badge rounded-circle badge-primary position-absolute popper-badge" v-if="countNotifikasi">&nbsp;{{ /*countNotifikasi*/ }}</span>
          </button>
        </popper>
      </li>
      <li class="nav-item">
        <div class="nav-link d-inline-block h-100 p-0">
          <div class="d-flex"><div class="text-bold text-nowrap flex-grow-1 overflow-hidden" style="width: 0;text-align: right;text-overflow: ellipsis;">{{ name }}</div></div>
          <div class="mt-1 d-flex gap-3" style="gap: 3px;">
            <img height="15" :src="getMemberIcon()" v-if="isShipper" />
            <span>{{ role }}<span class="d-none d-sm-inline ml-1">MEMBER</span></span> <span class="badge h-100" :class="classVerifikasi">{{ infoVerifikasi }}</span>
          </div>
        </div>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <span class="nav-link px-1">
          <div class="image d-inline-block ml-1">
            <router-link to="/profile" ><img :src="imgUrl" class="image-header img-circle" alt="User Image"/></router-link>
          </div>
        </span>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#" v-on:click="signOut"><i class="fas fa-sign-out-alt"></i></a>
      </li>
    </ul>
  </nav>
</template>

<script>
import Vue from "vue";
import {auth, authFetch, formatCurrency} from '@/libs/hxcore';
import $ from "jquery";
import VTooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import Swal from "sweetalert2";
import NewNumber from "@/dialog/NewNumber";
import OneTimePassword from "@/dialog/OneTimePassword";

Vue.use(VTooltip, {
  themes: {
    'boscod': {
      $extend: 'tooltip',
      triggers: [
        'hover',
        'focus',
        'click',
        'touch',
      ],
      placement: 'auto',
    },
  },
});
export default {
  name: "AppLayoutNav",
  data: function(){
    return {
      isShipper: false,
      isShipperCs: false,
      infoSaldo: '',
      infoNotifikasi: [],
      countNotifikasi: 0,
      infostatus: '',
      name : '',
      role : '',
      imgUrl : '',
      infoVerifikasi: '',
      classVerifikasi: {},
      disableVerifikasi: true,
      formNewNumber:{
        code: '',
			  otpType: 'new_number',
        phoneNumber:''
      },
      form:{
        tel:''
      },
      countShowOTP:0,
    }
  },
  created: function(){
    var info = auth.user();
    if(info)
    {
      if(info.role_id == 11){
        this.isShipper = true;
        this.loadSaldo();
        this.loadNotifikasi();
        this.loadStatus();
      }

      this.name = info.display_name;
      this.role = info.role_name;
      this.imgUrl = info.img_url;
    }
    else{
      this.imgUrl = '/dist/img/user.jpeg';
    }
    
    authFetch(`/users/profile`).then((res) => {
      res.json().then((json) => {
        if (json.ktp_verify == '0') {
          this.infoVerifikasi = 'Belum verifikasi';
          this.classVerifikasi = 'badge-danger';
          this.disableVerifikasi = false;
        } else if (json.ktp_verify == '1') {
          this.infoVerifikasi = 'Terverifikasi';
          this.classVerifikasi = 'badge-success';
          this.disableVerifikasi = true;
        } else if (json.ktp_verify == '2') {
          this.infoVerifikasi = 'Proses verifikasi';
          this.classVerifikasi = 'badge-warning text-light';
          this.disableVerifikasi = false;
        } else if (json.ktp_verify == '3') {
          this.infoVerifikasi = 'Verifikasi gagal';
          this.classVerifikasi = 'badge-danger';
          this.disableVerifikasi = false;
        }
        if (json.verified_number != 't' && json.ktp_verify == '1' && info.role_id == 11) {
          Swal.fire({
          allowEscapeKey:false,
					title: 'Verifikasi nomor teleponmu',
					text: 'Sebelum melanjutkan, pastikan kamu telah melakukan verifikasi nomor telepon ya!',
          confirmButtonColor:'#FF7203',
          confirmButtonText: 'Verifikasi sekarang',
          allowOutsideClick: false
					}).then((result) => {
            if (result.isConfirmed) {
              this.loadOTP();
            }
          })
        }
        this.form.tel = json.kontak_hp
      });
    });
  },
  methods: {
    getMemberIcon() {
        if(!this.role)return "";
        const fileName = this.role.toLowerCase();
        return require(`../assets/img/member/${fileName}.svg`);
    },
    loadSaldo: function (code) {
      authFetch("/payment/saldo/current").then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.infoSaldo = formatCurrency(json.data);
          }
        });
      });
    },
    loadNotifikasi: function (code) {
      authFetch("/report/notification?message_type=private").then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.infoNotifikasi = json.data;
            if (json.data.length > 9) {
              this.countNotifikasi = 9;
            } else {
              this.countNotifikasi = json.data.length;
            }
          } else {
            this.infoNotifikas = [];
            this.countNotifikasi = 0;
          }
        });
      });
    },
    loadStatus: function (code) {
      authFetch("/order/courier/courierplus").then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.infostatus = json.data;
          }
        });
      });
    },
    signOut: function(e) {
      auth.logout();
      this.$router.push('/login');
      e.preventDefault();
    },
    detailOrder(url) {
      if (url) {
        let route = this.$router.resolve({
          path: url
        });
        window.open(route.href,"_blank");
      }
    },
    handleCustomChange (s) {
      this.form.code = s;
      this.submitNewNumber();
      // this.postData();
      // this.submitForm();
    },
    loadOTP: function (ev) {
      this.form.otpType = 'new_number';
      this.countShowOTP += 1;
      // ev.preventDefault();
    },
    closeOPT: function (ev) {
      this.form.otpType = 'new_number';
      // this.showOTP = true;
      this.countShowOTP = 0;
      // ev.preventDefault();
    },
    submitNewNumber() {
			const e = this.$refs;
			var data = Object.keys(this.form)
				.map(
				(key) =>
					encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
				)
				.join("&");
			this.method = "PUT";
			var urlSubmit = "/users/profile/update_number";
			
			console.log('sini',urlSubmit);
			authFetch(urlSubmit, {
				method: this.method,
				body: data,
			})
				.then((res) => {
				if (res.status === 201) {
				} else if (res.status === 400) {
				}
				return res.json();
				})
				.then((js) => {
				this.errors = [];
				if (js.success) {
					Swal.fire({
					title: 'Berhasil',
					icon: 'success',
					text: 'Nomor HP Berhasil Diverifikasi',
					showCloseButton: true
					})
					// $(this.$refs.formNewNumber).modal("hide");
					this.countShowOTP = 0;
				}
				else{
					Swal.fire({
					title: 'Gagal',
					icon: 'error',
					text: js.details.code,
					showCloseButton: true
					})
				}
			});
			

			console.log('sini');
			// ev.preventDefault();
		},
  },
  components: {
    'popper': Popper,
    NewNumber,
    OneTimePassword
  },
}
</script>

<style scoped>
.btninfo {
    font-size:17px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}
button.btninfo:focus {
    outline: none;
}
.popper {
  background-color: white;
  max-width: 97.5vw;
}
.popper .row {
  width: 100%;
  margin: 0;
}
.popper .row:hover {
  background-color: whitesmoke;
}
.popper .row.p-3.border-bottom {
  cursor: default;
}
.popper .row.p-3 {
  cursor: pointer;
}
.popper .row .text-left {
  inline-size: 350px;
  overflow-wrap: break-word;
}
.popper-button {
  width: 30px;
  height: 30px;
}
.popper-icon {
  width: 100%;
  height: auto;
  margin-top: 0.13rem;
  margin-bottom: 0;
}
.popper-icon:before {
  display: flex;
  justify-content: center;
  align-items: center;
}
.popper-badge {
  right: -1px;
  min-width: 12.6px;
}
.main-header {
  z-index: 1029;
}
</style>
