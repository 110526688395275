<template>
  <section class="content">
    <div
      class="modal show"
      tabindex="-1"
      role="dialog"
      ref="formTicket"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">Buat Tiket</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 form-group" v-if="!orderId">
                  <label class="control-label">Pesanan</label>
                  <v-select
                    required
                    label="name"
                    :filterable="false"
                    :options="orderOptions"
                    v-model="form.refcode"
                    :reduce="(opt) => opt.code"
                    @input="changeOrder"
                    @search="onSearch"
                  >
                    <template slot="no-options"
                      >Masukkan AWB/No Resi atau Nama Customer
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.label }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.label }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Prioritas</label>
                  <select class="form-control" required v-model="form.priority">
                    <option
                      v-for="priority in priorityOpt"
                      v-bind:key="priority.code"
                      v-bind:value="priority.code"
                    >
                      {{ priority.label }}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 form-group">
                  <label class="control-label">Kategori</label>
                  <v-select
                    :options="categories"
                    :reduce="(opt) => opt.code"
                    v-model="form.category"
                    @input="selectCategory"
                  ></v-select>
                </div>
                <div class="col-md-12 form-group" v-if="form.category == 6">
                  <label class="control-label">Judul</label>
                  <input
                    type="text"
                    maxlength="100"
                    class="form-control"
                    v-model="form.title"
                    required="required"
                  />
                </div>
                <div class="col-md-12 form-group">
                  <label class="control-label">Pesan</label>
                  <textarea
                    class="form-control"
                    v-model="form.message"
                    required="required"
                    onkeydown="return (event.ctrlKey || event.altKey 
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==57  && event.shiftKey==true)
                    || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==48  && event.shiftKey==true)
                    || (event.keyCode==190  && event.shiftKey==false) || (event.keyCode==188  && event.shiftKey==false)
                    || (34<event.keyCode && event.keyCode<40) || (event.keyCode==46) || (event.keyCode==32) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))"
                  ></textarea>
                  <small class="form-text text-muted">Maksimal 400 karakter</small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <input
                    type="file"
                    accept="image/*"
                    name="userfile[]"
                    id="userfile"
                    ref="userfile"
                    multiple
                    style="display: none"
                    @change="uploadFiles"
                  />
                  <button
                    type="button"
                    @click="showUpload"
                    class="btn-xs btn btn-secondary"
                  >
                    <i class="fa fa-paperclip"></i> Lampiran
                  </button>
                  <small class="ml-2 text-danger" v-if="uploadMessage">{{uploadMessage}}</small>
                  <ul class="pt-2">
                    <li v-for="file in files">
                      {{ file.name }}
                      <span>
                        <div
                          class="spinner-border spinner-border-sm"
                          role="status"
                          v-if="!file.status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <button
                          v-if="file.status"
                          :data-id="file.code"
                          @click="removeFile(file.code)"
                          type="button"
                          class="btn btn-xs"
                        >
                          <i class="fa fa-times"></i>
                        </button>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6 text-right">
                  <button type="submit" class="btn btn-primary">
                    Laporkan
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal show"
      tabindex="-1"
      role="dialog"
      ref="formUpload"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <form role="form">
            <div class="modal-body">upload</div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { authFetch, charsRemover } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "vue-select/dist/vue-select.css";
export default {
  name: "Ticket",
  props: { show: Boolean, value: Object, orderId: String },
  data() {
    return {
      orderOptions: [],
      errors: [],
      categories: [],
      priorityOpt: [],
      method: "POST",
      selectedId: 0,
      defaultId: 1,
      addrOptions: [],
      addrJne: [],
      courierBranchOption: [],
      formTitle: "Buat Tiket",
      files: [],
      loaded: false,
      form: {
        //to create pickup address
        id: "",
        priority: "",
        category: "",
        message: "",
        title: "",
        refcode: "",
        attachments: "",
      },
      uploadMessage:''
    };
  },
  components: {
    vSelect,
    maskedInput,
    CurrencyInput,
  },
  created() {},
  methods: {
    submitForm: function (ev) {
      this.$gtag.event(`click`, {
        'event_name': 'Buat Tiket'
      });
      var items = [];
      /*if(this.files.length == 0)
      {
        Swal.fire("Pilih lampiran", "Lampiran tidak boleh kosong", "error");
        return false;
      }*/
      for (var i in this.files) {
        items.push(this.files[i].code);
      }
      this.form.attachments = items.join(",");
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      authFetch("/ticket", {
        method: "POST",
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          if (js.success) {
            $(this.$refs.formTicket).modal("hide");
            this.form = {};
            this.files = [];
            this.$emit("change", "0");
            this.$root.$emit("reloadDashboardTicket");
          }
        });
    },
    onSearch(search, loading) {
      if (search.length > 5) {
        loading(true);
        //this.search(loading, search, this);
        var vm = this;
        authFetch(`/ticket/order?q=${escape(search)}`).then((res) => {
          res.json().then((json) => (vm.orderOptions = json.items));
          loading(false);
        });
      }
    },
    changeOrder(e)
    {
      this.uploadMessage = "";
      this.files = [];
    },
    selectCategory: function (v) {
      let cat = this.categories.filter(function (arr, i) {
        return arr.code == v;
      });
      if (cat[0].code != 6) this.form.title = cat[0].label;
      else this.form.title = "";
    },
    showUpload: function (ev) {
      //$(this.$refs.formUpload).modal("show");
      if(!this.form.refcode)
      {
        this.uploadMessage = "Pilih pesanan terlebih dahulu";
        return;
      }
      this.uploadMessage = "";
      $(this.$refs.userfile).click();
    },
    uploadFiles: function (event) {
      const e = this.$refs;
      var input = event.target;
      this.files = input.files;
      var formData = new FormData();
      for (var i in this.files) {
        formData.append("file[]", input.files[i]);
      }
      formData.append("refcode", this.form.refcode);
      //formData.append("subdir", "/ticket/" + new Date().getTime() / 1000);
      authFetch("/ticket/upload", {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": null,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          this.files = json;
        });
    },
    removeFile: function (code) {
      let newFiles = this.files.filter(function (arr, i) {
        return arr.code != code;
      });
      this.files = newFiles;
    },
    loadCategories: function (id) {
      authFetch("/ticket/categories").then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.categories = json.data;
          }
        });
      });
    },
    loadPriority: function (id) {
      authFetch("/ticket/priority").then((res) => {
        res.json().then((json) => {
          if (json.success) {
            this.priorityOpt = json.data;
          }
        });
      });
    },
  },
  mounted() {
    // console.log("warehouse1", this.value);
    const e = this.$refs;
    // if (this.show === true) $(e.formSelectPickup).modal("show");
    // console.log(this.show);
    $(e.formTicket).on("show.bs.modal", (e) => {
      if (!this.loaded) {
        this.loadCategories();
        this.loadPriority();
        this.loaded = true;
      }
    });

    $(e.formTicket).on("hide.bs.modal", (e) => {
      this.$emit("hide", true);
    });
  },
  watch: {
    show: function (old, val) {
      $(this.$refs.formTicket).modal("show");
    },
    orderId: function (old, val) {
      this.form.refcode = this.orderId;
    },
    value: function (old, val) {},
    form: {
      handler(val) {
        this.form.message = charsRemover(val.message);
      },
      deep: true
    },
  },
};
</script>
<style scoped>
</style>