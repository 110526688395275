const routes = [
    {
        path: '/ppob/history',
        name: 'History PPOB',
        component: () =>
            import ('@/views/ppob/PpobHistory.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.ppob.all'
        }
    },
    {
        path: '/ppob/products',
        name: 'PPOB Product Items',
        component: () =>
            import ('@/views/ppob/PpobProducts.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.ppob.all'
        }
    },
    {
        path: '/ppob/rekonsiliasi/winpay',
        name: 'Upload Rekonsiliasi Invoice Winpay (PPOB)',
        component: () =>
            import ('@/views/ppob/FormRekonPPOBWinpay.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.ppob.all'
        }
    },
    {
        path: '/ppob/subsidiary-ledger',
        name: 'Laporan Subsidiary Ledger akun Uang Muka',
        component: () =>
            import ('@/views/ppob/SubsidiaryLedger.vue'),
        meta: {
            layout: 'AppLayoutAdmin',
            requiresAuth: true,
            code: 'cod.ppob.all'
        }
    },
]

export default routes