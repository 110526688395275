const sideMenukurir = [
    { label: 'Dashboard', url: '/dashboard-kurir', icon: 'fas fa-tachometer-alt' },
    { label: 'Riwayat Kiriman', url: '/riwayat-kurir/all', icon: 'far fa-clock' },
    {
        label: 'Tiket & Klaim',
        url: '#',
        icon: 'fa fa-cog',
        menus: [
            { label: 'Buat Tiket Baru', url: () => {
                //this.$root.$emit('createTicket', 3);
            }, icon: 'far fa-circle' },
            { label: 'Daftar Tiket', url: '/settings/tickets/all', icon: 'far fa-circle' },
            { label: 'Daftar Klaim', url: '/settings/tickets/claimcourier', icon: 'far fa-circle' },
        ]
    },
    {
        label: 'Pengaturan',
        url: '#',
        icon: 'fa fa-cog',
        menus: [
            { label: 'Profil', url: '/profile', icon: 'far fa-circle' },
        ]
    }
    //{ label: 'Payment', url: '/payment-kurir', icon: 'fas fa-money-bill' },

];
export default sideMenukurir;
