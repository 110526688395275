<template>
	<section class="content">
    <div v-for="item in dataAlert" v-bind:key="item.id">
        <div v-bind:style="`${item.page == 'layout' ? 'border-radius:0;' : ''}`" class="" v-bind:class="`alert alert-${item.type} ${item.page == 'layout' ? 'm-0' : ''} ${item.dismissible == 1 ? 'alert-dismissible fade show' : ''}`" role="alert" v-if="show">
            <div v-html="item.title" v-if="item.title != '' || item.title != null"></div>
            <div v-html="item.message"></div>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" v-if="item.dismissible == 1">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
	</section>
</template>
<script>
import { authFetch, auth } from "@/libs/hxcore";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
	name: "Alert",
	props: {
		page: String,
		position: String
	},
	data() {
		return {
			orderOptions: [],
			errors: [],
			categories: [],
			priorityOpt: [],
			method: "POST",
			selectedId: 0,
			defaultId: 1,
			addrOptions: [],
			dataAlert: [],
			addrJne: [],
			courierBranchOption: [],
			formTitle: "Buat Tiket",
			files: [],
			show: false,
			submitType: '',
			form: {
				page: "",
				position: "",
			},
			referralList: [],
			referralCode: "",
			referralLink: "",
		};
	},
	components: {
	},
	created() {
        let self = this;
        self.form.page = self.page;
        self.form.position = self.position;
		self.userInfo = auth.user();

        let params = self.form;
        let data = Object.keys(params)
        .map(
          key =>
            encodeURIComponent(key) +
            "=" +
            (params[key] === false || params[key] === undefined
              ? 0
              : encodeURIComponent(params[key]))
        )
        .join("&");

        authFetch("/info/groups?"+data)
            .then((res) => {
                if (res.status === 201) {} else if (res.status === 400) {}
                return res.json();
            })
            .then((js) => {
                if (js.data == 'no data') {
                    this.show = false;
                } else {
                    this.show = true;
                    self.dataAlert = js.data;
                }
            });
	},
	methods: {
	},
	mounted() {
		const e = this.$refs;
	},
	watch: {
		show: function (val, old) {
		},
	}
};
</script>
<style scoped>
</style>